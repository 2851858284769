

export default async function (session, firebase) {
  // Can only be initiated on client side
  if (!process.client) {
    return
  }

  await import('firebase/compat/messaging')

  if (firebase.messaging.isSupported()) {
    const messagingService = session.messaging()

    messagingService.getToken({vapidKey: "BFiIzIIFboiZT5vxAYnA_-e6U-fdaZDf9AQA9mWIkJk3V70sY--dPFsiwwt1Q-Mp9vQkYNrZHtd8D_uNn-39N1c"})

    return messagingService
  }
}

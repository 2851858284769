import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$790c from '/data/www/vilearn/web/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as ipxRuntime$7252 from '/data/www/vilearn/web/node_modules/@nuxt/image/dist/runtime/providers/ipx.js'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "banner": {
      "modifiers": {
        "format": "webp",
        "quality": 90,
        "loading": "lazy",
        "width": 1920,
        "height": 581,
        "fit": "contain"
      }
    },
    "bannerSmall": {
      "modifiers": {
        "format": "webp",
        "quality": 90,
        "loading": "lazy",
        "width": 834,
        "height": 446,
        "fit": "contain"
      }
    },
    "topic": {
      "modifiers": {
        "format": "webp",
        "quality": 90,
        "loading": "lazy",
        "width": 327,
        "height": 193,
        "fit": "contain",
        "sizes": "sm:200px md:300px lg:400px xl:500px"
      }
    },
    "webp": {
      "modifiers": {
        "format": "webp",
        "quality": 90,
        "loading": "lazy"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "api-dev.colearn.vn",
    "api-dev.colearn.vn:8413",
    "4085cfa4432979f.cmccloud.com.vn",
    "static.colearn.vn:8413",
    "api.vilearn.vn",
    "chatai.vilearn.vn"
  ],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$790c, defaults: {} },
  ['ipx']: { provider: ipxRuntime$7252, defaults: {} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1059462e = () => interopDefault(import('../pages/active/index.vue' /* webpackChunkName: "pages/active/index" */))
const _0974836b = () => interopDefault(import('../pages/app-redirect/index.vue' /* webpackChunkName: "pages/app-redirect/index" */))
const _063d3b64 = () => interopDefault(import('../pages/bang-xep-hang/index.vue' /* webpackChunkName: "pages/bang-xep-hang/index" */))
const _516026f6 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _3c984a6e = () => interopDefault(import('../pages/bo-de-old/index.vue' /* webpackChunkName: "pages/bo-de-old/index" */))
const _60af12a5 = () => interopDefault(import('../pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _2e22e450 = () => interopDefault(import('../pages/chat-ai/index.vue' /* webpackChunkName: "pages/chat-ai/index" */))
const _02af648a = () => interopDefault(import('../pages/dang-ky-gia-su/index.vue' /* webpackChunkName: "pages/dang-ky-gia-su/index" */))
const _77e2194e = () => interopDefault(import('../pages/delete/index.vue' /* webpackChunkName: "pages/delete/index" */))
const _4f29ed70 = () => interopDefault(import('../pages/doi-mat-khau/index.vue' /* webpackChunkName: "pages/doi-mat-khau/index" */))
const _e65c8c2a = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _248e2577 = () => interopDefault(import('../pages/gioi-thieu/index.vue' /* webpackChunkName: "pages/gioi-thieu/index" */))
const _30efac75 = () => interopDefault(import('../pages/goi-cuoc/index.vue' /* webpackChunkName: "pages/goi-cuoc/index" */))
const _77ea0b12 = () => interopDefault(import('../pages/hoi-bai/index.vue' /* webpackChunkName: "pages/hoi-bai/index" */))
const _7b1e5f96 = () => interopDefault(import('../pages/hoi-bai-cua-toi/index.vue' /* webpackChunkName: "pages/hoi-bai-cua-toi/index" */))
const _df22594c = () => interopDefault(import('../pages/huong-dan-cong-diem/index.vue' /* webpackChunkName: "pages/huong-dan-cong-diem/index" */))
const _d66eda4c = () => interopDefault(import('../pages/huong-dan-su-dung/index.vue' /* webpackChunkName: "pages/huong-dan-su-dung/index" */))
const _8e1332ca = () => interopDefault(import('../pages/khoa-hoc-old/index.vue' /* webpackChunkName: "pages/khoa-hoc-old/index" */))
const _7002d7da = () => interopDefault(import('../pages/khong-ton-tai/index.vue' /* webpackChunkName: "pages/khong-ton-tai/index" */))
const _1167195e = () => interopDefault(import('../pages/lich-su-thanh-toan/index.vue' /* webpackChunkName: "pages/lich-su-thanh-toan/index" */))
const _a53f163a = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _73a7d0d5 = () => interopDefault(import('../pages/noi-quy-hoi-bai/index.vue' /* webpackChunkName: "pages/noi-quy-hoi-bai/index" */))
const _02605f82 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _195fafbb = () => interopDefault(import('../pages/tai-lieu-old/index.vue' /* webpackChunkName: "pages/tai-lieu-old/index" */))
const _2535fc08 = () => interopDefault(import('../pages/thong-tin-ca-nhan/index.vue' /* webpackChunkName: "pages/thong-tin-ca-nhan/index" */))
const _ff714c24 = () => interopDefault(import('../pages/thu-vien-cua-toi/index.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/index" */))
const _336cf03e = () => interopDefault(import('../pages/tim-kiem/index.vue' /* webpackChunkName: "pages/tim-kiem/index" */))
const _c5b16f10 = () => interopDefault(import('../pages/xac-thuc/index.vue' /* webpackChunkName: "pages/xac-thuc/index" */))
const _832704f6 = () => interopDefault(import('../pages/blog/search.vue' /* webpackChunkName: "pages/blog/search" */))
const _1dd4a9ca = () => interopDefault(import('../pages/bo-de-old/chuyen-de/index.vue' /* webpackChunkName: "pages/bo-de-old/chuyen-de/index" */))
const _730a6e1b = () => interopDefault(import('../pages/bo-de-old/huong-dan.vue' /* webpackChunkName: "pages/bo-de-old/huong-dan" */))
const _ac274e54 = () => interopDefault(import('../pages/bo-de-old/index_old.vue' /* webpackChunkName: "pages/bo-de-old/index_old" */))
const _6cc9e601 = () => interopDefault(import('../pages/event/invite/index.vue' /* webpackChunkName: "pages/event/invite/index" */))
const _7638fe66 = () => interopDefault(import('../pages/gioi-thieu/mobile.vue' /* webpackChunkName: "pages/gioi-thieu/mobile" */))
const _54569683 = () => interopDefault(import('../pages/khoa-hoc-old/index_old.vue' /* webpackChunkName: "pages/khoa-hoc-old/index_old" */))
const _19615a41 = () => interopDefault(import('../pages/khong-ton-tai/chat-ai/index.vue' /* webpackChunkName: "pages/khong-ton-tai/chat-ai/index" */))
const _5eb64fa3 = () => interopDefault(import('../pages/tai-lieu-old/index_old.vue' /* webpackChunkName: "pages/tai-lieu-old/index_old" */))
const _3d44d85c = () => interopDefault(import('../pages/thong-tin-khac/bao-hanh-va-quan-ly-khieu-nai/index.vue' /* webpackChunkName: "pages/thong-tin-khac/bao-hanh-va-quan-ly-khieu-nai/index" */))
const _61fa356e = () => interopDefault(import('../pages/thong-tin-khac/chinh-sach-bao-mat/index.vue' /* webpackChunkName: "pages/thong-tin-khac/chinh-sach-bao-mat/index" */))
const _54869a8e = () => interopDefault(import('../pages/thong-tin-khac/chinh-sach-thanh-toan/index.vue' /* webpackChunkName: "pages/thong-tin-khac/chinh-sach-thanh-toan/index" */))
const _df684f36 = () => interopDefault(import('../pages/thong-tin-khac/dieu-khoan-su-dung/index.vue' /* webpackChunkName: "pages/thong-tin-khac/dieu-khoan-su-dung/index" */))
const _cae9962c = () => interopDefault(import('../pages/thong-tin-khac/gioi-thieu/index.vue' /* webpackChunkName: "pages/thong-tin-khac/gioi-thieu/index" */))
const _ecbfd5c8 = () => interopDefault(import('../pages/thu-vien-cua-toi/chat-ai/index.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chat-ai/index" */))
const _f3d62390 = () => interopDefault(import('../pages/thu-vien/chuyen-de/index.vue' /* webpackChunkName: "pages/thu-vien/chuyen-de/index" */))
const _4ac0c683 = () => interopDefault(import('../pages/thu-vien/de-thi/index.vue' /* webpackChunkName: "pages/thu-vien/de-thi/index" */))
const _ea679602 = () => interopDefault(import('../pages/thu-vien/search.vue' /* webpackChunkName: "pages/thu-vien/search" */))
const _6225d5d8 = () => interopDefault(import('../pages/thu-vien/tai-lieu/index.vue' /* webpackChunkName: "pages/thu-vien/tai-lieu/index" */))
const _05b43f36 = () => interopDefault(import('../pages/thu-vien/thu-vien-cua-toi/index.vue' /* webpackChunkName: "pages/thu-vien/thu-vien-cua-toi/index" */))
const _507a8410 = () => interopDefault(import('../pages/thu-vien/thu-vien-cua-toi-new/index.vue' /* webpackChunkName: "pages/thu-vien/thu-vien-cua-toi-new/index" */))
const _0b2e03aa = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/da-lam.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/da-lam" */))
const _53733d79 = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/tam-dung.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/tam-dung" */))
const _2475532c = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/dang-hoc" */))
const _2bcaab54 = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/theo-doi" */))
const _698c7f92 = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/dang-hoc.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/dang-hoc" */))
const _2f31d6bc = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/theo-doi.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/theo-doi" */))
const _0d0f3b1d = () => interopDefault(import('../pages/thu-vien-cua-toi/tai-lieu/da-xem.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/tai-lieu/da-xem" */))
const _01c298a6 = () => interopDefault(import('../pages/thu-vien/de-thi/guide.vue' /* webpackChunkName: "pages/thu-vien/de-thi/guide" */))
const _dc24bdfe = () => interopDefault(import('../pages/thu-vien/de-thi/lam-bai/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/lam-bai/_slug" */))
const _9554c6e8 = () => interopDefault(import('../pages/thu-vien/de-thi/xem/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/xem/_slug" */))
const _6a38f086 = () => interopDefault(import('../pages/blog/danh-muc/_slug.vue' /* webpackChunkName: "pages/blog/danh-muc/_slug" */))
const _2141ec5a = () => interopDefault(import('../pages/bo-de-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/bo-de-old/chuyen-de/_slug" */))
const _736a4221 = () => interopDefault(import('../pages/bo-de-old/ket-qua/_slug.vue' /* webpackChunkName: "pages/bo-de-old/ket-qua/_slug" */))
const _4737ec28 = () => interopDefault(import('../pages/bo-de-old/lam-bai/_slug.vue' /* webpackChunkName: "pages/bo-de-old/lam-bai/_slug" */))
const _076abdef = () => interopDefault(import('../pages/khoa-hoc-old/chi-tiet/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/chi-tiet/_slug" */))
const _3dfd91c0 = () => interopDefault(import('../pages/khoa-hoc-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/chuyen-de/_slug" */))
const _7a941a40 = () => interopDefault(import('../pages/tai-lieu-old/chuyen-de/_slug.vue' /* webpackChunkName: "pages/tai-lieu-old/chuyen-de/_slug" */))
const _53ce2718 = () => interopDefault(import('../pages/thu-vien-cua-toi/bo-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/bo-de/_slug" */))
const _7d20b953 = () => interopDefault(import('../pages/thu-vien-cua-toi/chuyen-de/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/chuyen-de/_slug" */))
const _2cdd52a6 = () => interopDefault(import('../pages/thu-vien-cua-toi/khoa-hoc/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/khoa-hoc/_slug" */))
const _7967a066 = () => interopDefault(import('../pages/thu-vien-cua-toi/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien-cua-toi/tai-lieu/_slug" */))
const _f7436620 = () => interopDefault(import('../pages/thu-vien/chuyen-de/_slug.vue' /* webpackChunkName: "pages/thu-vien/chuyen-de/_slug" */))
const _490a253b = () => interopDefault(import('../pages/thu-vien/de-thi/_slug.vue' /* webpackChunkName: "pages/thu-vien/de-thi/_slug" */))
const _2fc10e04 = () => interopDefault(import('../pages/thu-vien/live/_slug.vue' /* webpackChunkName: "pages/thu-vien/live/_slug" */))
const _606f3490 = () => interopDefault(import('../pages/thu-vien/tai-lieu/_slug.vue' /* webpackChunkName: "pages/thu-vien/tai-lieu/_slug" */))
const _759ea9a2 = () => interopDefault(import('../pages/thu-vien/video/_slug.vue' /* webpackChunkName: "pages/thu-vien/video/_slug" */))
const _54cd6986 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _3ae1a926 = () => interopDefault(import('../pages/bo-de-old/_slug.vue' /* webpackChunkName: "pages/bo-de-old/_slug" */))
const _740bc252 = () => interopDefault(import('../pages/cau-hoi/_slug.vue' /* webpackChunkName: "pages/cau-hoi/_slug" */))
const _9180755a = () => interopDefault(import('../pages/khoa-hoc-old/_slug.vue' /* webpackChunkName: "pages/khoa-hoc-old/_slug" */))
const _17a90e73 = () => interopDefault(import('../pages/tai-lieu-old/_slug.vue' /* webpackChunkName: "pages/tai-lieu-old/_slug" */))
const _5e4d34b2 = () => interopDefault(import('../pages/tra-loi/_slug.vue' /* webpackChunkName: "pages/tra-loi/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/active",
    component: _1059462e,
    name: "active"
  }, {
    path: "/app-redirect",
    component: _0974836b,
    name: "app-redirect"
  }, {
    path: "/bang-xep-hang",
    component: _063d3b64,
    name: "bang-xep-hang"
  }, {
    path: "/blog",
    component: _516026f6,
    name: "blog"
  }, {
    path: "/bo-de-old",
    component: _3c984a6e,
    name: "bo-de-old"
  }, {
    path: "/callback",
    component: _60af12a5,
    name: "callback"
  }, {
    path: "/chat-ai",
    component: _2e22e450,
    name: "chat-ai"
  }, {
    path: "/dang-ky-gia-su",
    component: _02af648a,
    name: "dang-ky-gia-su"
  }, {
    path: "/delete",
    component: _77e2194e,
    name: "delete"
  }, {
    path: "/doi-mat-khau",
    component: _4f29ed70,
    name: "doi-mat-khau"
  }, {
    path: "/download",
    component: _e65c8c2a,
    name: "download"
  }, {
    path: "/gioi-thieu",
    component: _248e2577,
    name: "gioi-thieu"
  }, {
    path: "/goi-cuoc",
    component: _30efac75,
    name: "goi-cuoc"
  }, {
    path: "/hoi-bai",
    component: _77ea0b12,
    name: "hoi-bai"
  }, {
    path: "/hoi-bai-cua-toi",
    component: _7b1e5f96,
    name: "hoi-bai-cua-toi"
  }, {
    path: "/huong-dan-cong-diem",
    component: _df22594c,
    name: "huong-dan-cong-diem"
  }, {
    path: "/huong-dan-su-dung",
    component: _d66eda4c,
    name: "huong-dan-su-dung"
  }, {
    path: "/khoa-hoc-old",
    component: _8e1332ca,
    name: "khoa-hoc-old"
  }, {
    path: "/khong-ton-tai",
    component: _7002d7da,
    name: "khong-ton-tai"
  }, {
    path: "/lich-su-thanh-toan",
    component: _1167195e,
    name: "lich-su-thanh-toan"
  }, {
    path: "/maintenance",
    component: _a53f163a,
    name: "maintenance"
  }, {
    path: "/noi-quy-hoi-bai",
    component: _73a7d0d5,
    name: "noi-quy-hoi-bai"
  }, {
    path: "/redirect",
    component: _02605f82,
    name: "redirect"
  }, {
    path: "/tai-lieu-old",
    component: _195fafbb,
    name: "tai-lieu-old"
  }, {
    path: "/thong-tin-ca-nhan",
    component: _2535fc08,
    name: "thong-tin-ca-nhan"
  }, {
    path: "/thu-vien-cua-toi",
    component: _ff714c24,
    name: "thu-vien-cua-toi"
  }, {
    path: "/tim-kiem",
    component: _336cf03e,
    name: "tim-kiem"
  }, {
    path: "/xac-thuc",
    component: _c5b16f10,
    name: "xac-thuc"
  }, {
    path: "/blog/search",
    component: _832704f6,
    name: "blog-search"
  }, {
    path: "/bo-de-old/chuyen-de",
    component: _1dd4a9ca,
    name: "bo-de-old-chuyen-de"
  }, {
    path: "/bo-de-old/huong-dan",
    component: _730a6e1b,
    name: "bo-de-old-huong-dan"
  }, {
    path: "/bo-de-old/index_old",
    component: _ac274e54,
    name: "bo-de-old-index_old"
  }, {
    path: "/event/invite",
    component: _6cc9e601,
    name: "event-invite"
  }, {
    path: "/gioi-thieu/mobile",
    component: _7638fe66,
    name: "gioi-thieu-mobile"
  }, {
    path: "/khoa-hoc-old/index_old",
    component: _54569683,
    name: "khoa-hoc-old-index_old"
  }, {
    path: "/khong-ton-tai/chat-ai",
    component: _19615a41,
    name: "khong-ton-tai-chat-ai"
  }, {
    path: "/tai-lieu-old/index_old",
    component: _5eb64fa3,
    name: "tai-lieu-old-index_old"
  }, {
    path: "/thong-tin-khac/bao-hanh-va-quan-ly-khieu-nai",
    component: _3d44d85c,
    name: "thong-tin-khac-bao-hanh-va-quan-ly-khieu-nai"
  }, {
    path: "/thong-tin-khac/chinh-sach-bao-mat",
    component: _61fa356e,
    name: "thong-tin-khac-chinh-sach-bao-mat"
  }, {
    path: "/thong-tin-khac/chinh-sach-thanh-toan",
    component: _54869a8e,
    name: "thong-tin-khac-chinh-sach-thanh-toan"
  }, {
    path: "/thong-tin-khac/dieu-khoan-su-dung",
    component: _df684f36,
    name: "thong-tin-khac-dieu-khoan-su-dung"
  }, {
    path: "/thong-tin-khac/gioi-thieu",
    component: _cae9962c,
    name: "thong-tin-khac-gioi-thieu"
  }, {
    path: "/thu-vien-cua-toi/chat-ai",
    component: _ecbfd5c8,
    name: "thu-vien-cua-toi-chat-ai"
  }, {
    path: "/thu-vien/chuyen-de",
    component: _f3d62390,
    name: "thu-vien-chuyen-de"
  }, {
    path: "/thu-vien/de-thi",
    component: _4ac0c683,
    name: "thu-vien-de-thi"
  }, {
    path: "/thu-vien/search",
    component: _ea679602,
    name: "thu-vien-search"
  }, {
    path: "/thu-vien/tai-lieu",
    component: _6225d5d8,
    name: "thu-vien-tai-lieu"
  }, {
    path: "/thu-vien/thu-vien-cua-toi",
    component: _05b43f36,
    name: "thu-vien-thu-vien-cua-toi"
  }, {
    path: "/thu-vien/thu-vien-cua-toi-new",
    component: _507a8410,
    name: "thu-vien-thu-vien-cua-toi-new"
  }, {
    path: "/thu-vien-cua-toi/bo-de/da-lam",
    component: _0b2e03aa,
    name: "thu-vien-cua-toi-bo-de-da-lam"
  }, {
    path: "/thu-vien-cua-toi/bo-de/tam-dung",
    component: _53733d79,
    name: "thu-vien-cua-toi-bo-de-tam-dung"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/dang-hoc",
    component: _2475532c,
    name: "thu-vien-cua-toi-chuyen-de-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/theo-doi",
    component: _2bcaab54,
    name: "thu-vien-cua-toi-chuyen-de-theo-doi"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/dang-hoc",
    component: _698c7f92,
    name: "thu-vien-cua-toi-khoa-hoc-dang-hoc"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/theo-doi",
    component: _2f31d6bc,
    name: "thu-vien-cua-toi-khoa-hoc-theo-doi"
  }, {
    path: "/thu-vien-cua-toi/tai-lieu/da-xem",
    component: _0d0f3b1d,
    name: "thu-vien-cua-toi-tai-lieu-da-xem"
  }, {
    path: "/thu-vien/de-thi/guide",
    component: _01c298a6,
    name: "thu-vien-de-thi-guide"
  }, {
    path: "/thu-vien/de-thi/lam-bai/:slug?",
    component: _dc24bdfe,
    name: "thu-vien-de-thi-lam-bai-slug"
  }, {
    path: "/thu-vien/de-thi/xem/:slug?",
    component: _9554c6e8,
    name: "thu-vien-de-thi-xem-slug"
  }, {
    path: "/blog/danh-muc/:slug?",
    component: _6a38f086,
    name: "blog-danh-muc-slug"
  }, {
    path: "/bo-de-old/chuyen-de/:slug?",
    component: _2141ec5a,
    name: "bo-de-old-chuyen-de-slug"
  }, {
    path: "/bo-de-old/ket-qua/:slug?",
    component: _736a4221,
    name: "bo-de-old-ket-qua-slug"
  }, {
    path: "/bo-de-old/lam-bai/:slug?",
    component: _4737ec28,
    name: "bo-de-old-lam-bai-slug"
  }, {
    path: "/khoa-hoc-old/chi-tiet/:slug?",
    component: _076abdef,
    name: "khoa-hoc-old-chi-tiet-slug"
  }, {
    path: "/khoa-hoc-old/chuyen-de/:slug?",
    component: _3dfd91c0,
    name: "khoa-hoc-old-chuyen-de-slug"
  }, {
    path: "/tai-lieu-old/chuyen-de/:slug?",
    component: _7a941a40,
    name: "tai-lieu-old-chuyen-de-slug"
  }, {
    path: "/thu-vien-cua-toi/bo-de/:slug?",
    component: _53ce2718,
    name: "thu-vien-cua-toi-bo-de-slug"
  }, {
    path: "/thu-vien-cua-toi/chuyen-de/:slug?",
    component: _7d20b953,
    name: "thu-vien-cua-toi-chuyen-de-slug"
  }, {
    path: "/thu-vien-cua-toi/khoa-hoc/:slug?",
    component: _2cdd52a6,
    name: "thu-vien-cua-toi-khoa-hoc-slug"
  }, {
    path: "/thu-vien-cua-toi/tai-lieu/:slug?",
    component: _7967a066,
    name: "thu-vien-cua-toi-tai-lieu-slug"
  }, {
    path: "/thu-vien/chuyen-de/:slug?",
    component: _f7436620,
    name: "thu-vien-chuyen-de-slug"
  }, {
    path: "/thu-vien/de-thi/:slug?",
    component: _490a253b,
    name: "thu-vien-de-thi-slug"
  }, {
    path: "/thu-vien/live/:slug?",
    component: _2fc10e04,
    name: "thu-vien-live-slug"
  }, {
    path: "/thu-vien/tai-lieu/:slug?",
    component: _606f3490,
    name: "thu-vien-tai-lieu-slug"
  }, {
    path: "/thu-vien/video/:slug?",
    component: _759ea9a2,
    name: "thu-vien-video-slug"
  }, {
    path: "/blog/:slug",
    component: _54cd6986,
    name: "blog-slug"
  }, {
    path: "/bo-de-old/:slug?",
    component: _3ae1a926,
    name: "bo-de-old-slug"
  }, {
    path: "/cau-hoi/:slug?",
    component: _740bc252,
    name: "cau-hoi-slug"
  }, {
    path: "/khoa-hoc-old/:slug?",
    component: _9180755a,
    name: "khoa-hoc-old-slug"
  }, {
    path: "/tai-lieu-old/:slug?",
    component: _17a90e73,
    name: "tai-lieu-old-slug"
  }, {
    path: "/tra-loi/:slug?",
    component: _5e4d34b2,
    name: "tra-loi-slug"
  }, {
    path: "/",
    component: _77ea0b12,
    name: "index"
  }, {
    path: "/blog-app",
    component: _516026f6,
    name: "blog-app"
  }, {
    path: "/blog-app/search",
    component: _832704f6,
    name: "blog-app-search"
  }, {
    path: "/blog-app/:slug",
    component: _54cd6986,
    name: "blog-app-slug"
  }, {
    path: "/blog-app/category/:slug",
    component: _6a38f086,
    name: "blog-app-category-slug"
  }, {
    path: "/hoi-bai/:slug",
    component: _77ea0b12,
    name: "hoi-bai-slug"
  }, {
    path: "/thu-vien",
    component: _f3d62390,
    name: "thu-vien"
  }, {
    path: "/library/quiz/guide",
    component: _01c298a6,
    name: "quiz-guide"
  }, {
    path: "/chuyen-de",
    component: _f3d62390,
    name: "chuyen-de"
  }, {
    path: "/chuyen-de/:slug",
    component: _f7436620,
    name: "chuyen-de-slug"
  }, {
    path: "/video/:slug",
    component: _759ea9a2,
    name: "video-slug"
  }, {
    path: "/tai-lieu",
    component: _6225d5d8,
    name: "tai-lieu"
  }, {
    path: "/tai-lieu/:slug",
    component: _606f3490,
    name: "tai-lieu-slug"
  }, {
    path: "/trac-nghiem",
    component: _4ac0c683,
    name: "trac-nghiem"
  }, {
    path: "/trac-nghiem/:slug",
    component: _490a253b,
    name: "trac-nghiem-slug"
  }, {
    path: "/trac-nghiem/lam-bai/:slug",
    component: _dc24bdfe,
    name: "trac-nghiem-lam-bai"
  }, {
    path: "/trac-nghiem/ket-qua/:slug",
    component: _9554c6e8,
    name: "trac-nghiem-ket-qua"
  }, {
    path: "/thu-vien-cua-toi",
    component: _05b43f36,
    name: "thu-vien-cua-toi"
  }, {
    path: "*",
    component: _7002d7da
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
